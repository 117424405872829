import React from 'react';
// components
import HeroSection from '../../sections/Product/components/common/HeroSection';
import SecondaryInfoSection from '../../sections/Product/components/common/SecondaryInfoSection';
import {
    criminalCheckBulletPoints,
    criminalCheckFaq,
    criminalCheckInfoCards,
} from '../../sections/Product/components/utilities';
import BuildTrust from '../../sections/Product/components/common/BuildTrust';
import MetaTagsProvider from '../../components/MetaTagsProvider';
import Coverage from '../../sections/Product/CriminalBgChecks/Coverage';
import HelpInfoSection from '../../sections/Product/CriminalBgChecks/HelpInfoSection';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import Schedule from '../../sections/Product/components/common/Schedule';
// assets
import Criminal_checks_demo from '../../images/product-assets/criminal-background-checks.gif';
import Criminal_check_flow from '../../images/product-assets/criminal-check-flow.svg';
// layout
import RootLayout from '../../layouts/RootLayout';
import Resources from '../../components/reusable/Resources';
import { CRIMINAL_BG_CHECK_PAGE_RESOURCES } from '../../helper/constant';

function CriminalChecks() {
    return (
        <>
            <MetaTagsProvider
                title="Authenticate: Comprehensive Criminal Background Checks for Employment, Rentals, and Fraud Prevention"
                description="Streamline your screening process with Authenticate's instant criminal background checks. Protect your business in employment, housing, and prevent fraud with reliable data."
                path="products/criminal-background-checks"
                ogImage="https://cdn.authenticating.com/public/website/product_pages/criminal_background_checks_og_image.jpg"
                ogTitle="Instant & Comprehensive Criminal Background Checks"
                keywords="criminal background check, criminal, history check, employment screening, criminal record check, tenant screening, fraud prevention, instant background checks, comprehensive screening, compliance, risk management"
                ogDescription="Secure your business with Authenticate's instant criminal background checks. Access reliable data to prevent fraud and ensure compliance."
                twitterTitle="Authenticate: Instant Criminal Background Checks for Businesses"
                twitterDescription="Secure your business with Authenticate's comprehensive criminal background checks for employment, rentals, and fraud prevention. Fast and reliable."
            />
            <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=products&utm_medium=criminal_check&utm_campaign=navbar">
                <HeroSection
                    tag="Products · Criminal Background Check"
                    heading="Mitigate Risks with Criminal Background Checks"
                    subHeading="Authenticate offers instant and more comprehensive background checks for enhanced security. With options for both more comprehensive and instant criminal background checks, Authenticate provides enhanced security while minimizing costs."
                    heroImage={Criminal_checks_demo}
                    signUpCtaLink="https://portal.authenticate.com/register?utm_source=products&utm_medium=criminal_check&utm_campaign=hero"
                />
                <SecondaryInfoSection
                    title="Detailed Instant reports to boost security and reduce fraud"
                    desc1="Criminal background checks help mitigate risks, ensure compliance, enhance safety, and protect reputation by identifying individuals with criminal histories, enabling informed decision-making, and safeguarding businesses and stakeholders."
                    desc2="This set of solutions ranges from criminal record indicators to the past seven years of criminal activity. Along with thorough federal and county-level checks, this service ensures thorough and reliable criminal background verification to keep your business safe and secure."
                    bulletPoints={criminalCheckBulletPoints}
                    sectionImage={Criminal_check_flow}
                />
                <BuildTrust cardInfo={criminalCheckInfoCards} />
                <Coverage />
                <HelpInfoSection />
                <Resources item={CRIMINAL_BG_CHECK_PAGE_RESOURCES} />
                <ProductFaq
                    faqData={criminalCheckFaq}
                    titleColor="#242424"
                    subHeading="Everything you need to know about Criminal Background Checks."
                />
                <Schedule getStartedCtaLink="https://portal.authenticate.com/register?utm_source=products&utm_medium=criminal_check&utm_campaign=footer" />
            </RootLayout>
        </>
    );
}

export default CriminalChecks;
